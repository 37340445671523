<template>
	<div class="admin-container">
		<Head />
		<div class="admin-main flex">
			<div class="admin-aside">
				<UserNavA></UserNavA>
			</div>
			<div class="admin-col whole_000 whole_fff">
				<div class="container">
					<div class="exte-box">
						<div class="exte-table">
							<el-table :data="tableData">
								<el-table-column prop="username" :label="$t('inth1')">
								</el-table-column>
								<el-table-column prop="createTime" :label="$t('inth2')">
								</el-table-column>
								<el-table-column prop="realNameStatus" :label="$t('inth3')">
									<template slot-scope="scope">
										<span style="color: #42b983;" v-if="scope.row.realNameStatus == 2">
											{{$t('realstate2')}}
										</span>
										<span style="color: #FF0000;" v-else>
											{{$t('realstate')}}
										</span>
									</template>
								</el-table-column>
								<template slot="empty" class="exte_empty">
									<img src="../../assets/photo/exte1.png" alt="">
									<p>{{$t('czEmpty')}}</p>
								</template>
							</el-table>
						</div>
						<el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage"
							layout="prev, pager, next" :total="total" :background="true"></el-pagination>
					</div>
				</div>
			</div>
		</div>
		<Foot />
	</div>
</template>

<script>
	import {
		promotionRecord
	} from '@/api/api/user'
	import UserNavA from '@/components/UserNavA.vue'
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	export default {
		name: 'Home',
		components: {
			Head,
			UserNavA,
			Foot
		},
		data() {
			return {
				tableData: [],
				currentPage: 1,
				pageSize: 10,
				total: 10
			};
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				promotionRecord({
					pageNo: this.currentPage,
					pageSize: this.pageSize
				}).then(res => {
					if (res.code == 0) {
						this.tableData = res.data
						this.total = res.totalElements
					} else {
						this.$message.error(this.$t('loginFirst'));
					}
				})
			},
			handleCurrentChange(val) {
				this.currentPage = val
				this.getList()
			}
		}
	}
</script>
